export const PROPERTIES_ROUTE = "/properties";
export const ADD_PROPERTY_ROUTE = "/properties/add";
export const AGENTS_ROUTE = "/agents";
export const ADD_AGENT_ROUTE = "/agents/add";
export const PROFILE_ROUTE = "/profile";
export const EDIT_PROPERTY_ROUTE = "/properties/edit/";
export const EDIT_AGENT_ROUTE = "/agents/edit/";
export const LEADS_ROUTE = "/leads";
export const ADD_LEAD_ROUTE = '/leads/add';
export const EDIT_LEAD_ROUTE = '/leads/edit/'

export const LEAD_AGENT_ROUTE = "/agent/leads/:id";
export const VIEW_ROUTE = '/view/:id';
export const APPOINTMENT_ROUTE = '/viewingarranged/:id';
export const VIEWING_DONE_ROUTE = '/viewdone/:id';
export const DEAL_DEAL_ROUTE = '/deal/:id';
export const VIEW_DETAILS_ROUTE = '/details/:id';
export const HISTORY_ROUTE = '/history/:id';

export const ACCOUNTS_ROUTE = '/accounts';
export const CONTACTS_ROUTE = '/contacts';
export const ACCOUNT_DEALS_ROUTE = ACCOUNTS_ROUTE + '/deals';
export const TRANSACTION_DETAILS_ROUTE = ACCOUNT_DEALS_ROUTE + '/transaction';
export const ACCOUNT_ADD_TRANSACTION_ROUTE = ACCOUNT_DEALS_ROUTE + '/add/';
export const LEADS_POOL_ROUTE ='/pool';
