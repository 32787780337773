import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useRef } from "react";

export const LeadTable = ({ leads, isLoading, columns, onChangeAction, setSelectedLeads }) => {
    const tableRef = useRef(null);
    const isMobileScreen = useMediaQuery("(max-width:768px)");
    const leadActions = [
        { id: 0, label: "Assign to Another Agent" },
    ];

    return (
        <MaterialReactTable
            state={{ isLoading: isLoading }}
            tableInstanceRef={tableRef}
            enableStickyHeader={!isMobileScreen}
            muiTableContainerProps={{
                sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 300px)" },
            }}
            columns={columns}
            data={leads ?? []}a
            enableRowSelection
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            getRowId={(row) => row.userid}
            onRowSelect={(row) => {
                setSelectedLeads((prevSelected) => [...prevSelected, row.original]);
            }}
            renderTopToolbarCustomActions={({ table }) => {
                const selectedLeads = table.getSelectedRowModel().flatRows.map((item) => item.original);

                return (
                    <TextField
                        select
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: "200px" }}
                        label="Action"
                        value=""
                        onChange={(e) => onChangeAction(e, selectedLeads)}
                    >
                        {leadActions.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            }}
        />
    );
};
