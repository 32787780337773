import { createBrowserRouter } from "react-router-dom";
// import { lazy } from "react";
// import { Box, CircularProgress } from "@mui/material";
// import { Suspense } from "react";
import Login from "../pages/Login";
import FullLayout from "../layouts/FullLayout/FullLayout";
import Dashboard from "../pages/Dashboard";

import {
  ACCOUNT_ADD_TRANSACTION_ROUTE,
  ACCOUNT_DEALS_ROUTE,
  ADD_AGENT_ROUTE,
  ADD_LEAD_ROUTE,
  ADD_PROPERTY_ROUTE,
  AGENTS_ROUTE,
  APPOINTMENT_ROUTE,
  CONTACTS_ROUTE,
  DEAL_DEAL_ROUTE,
  EDIT_AGENT_ROUTE,
  EDIT_LEAD_ROUTE,
  EDIT_PROPERTY_ROUTE,
  HISTORY_ROUTE,
  LEADS_POOL_ROUTE,
  LEADS_ROUTE,
  LEAD_AGENT_ROUTE,
  PROFILE_ROUTE,
  PROPERTIES_ROUTE,
  TRANSACTION_DETAILS_ROUTE,
  VIEWING_DONE_ROUTE,
  VIEW_DETAILS_ROUTE,
  VIEW_ROUTE,
} from "../constants/routes";
import Agents from "../pages/Agents";
import Properties from "../pages/Properties";
import AddAgent from "../pages/Agents/AddAgent";
import Profile from "../pages/Profile";
import RequireAuth from "../components/RequireAuth";
import EditProperty from "../pages/Properties/EditProperty";
import Signup from "../pages/Signup";
import EditAgent from "../pages/Agents/EditAgent";
import Leads from "../pages/Leads";
import AddLead from "../pages/Leads/AddLead";
import EditLead from "../pages/Leads/EditLead";
import { Box, CircularProgress } from "@mui/material";
import { selectCurrentagency } from "../redux/features/authSlice";
import { useSelector } from "react-redux";
import { useGetAgencyPropertiesQuery } from "../redux/api/propertiesApi";
import { PropertiesData } from "../hooks/useContext/agencyProperties";
import { LeadsData } from "../hooks/useContext/leadsData";
import { useGetLeadsQuery } from "../redux/api/leadsApi";
import { Suspense } from "react";
import Lead from "../pages/Leads/lead";
import ViewLead from "../pages/Leads/lead/ViewLead";
import LeadDetails from "../pages/Leads/lead/LeadDetails";
import History from "../pages/Leads/lead/History";
import ViewingArranged from "../pages/Leads/lead/status/ViewingArranged";
import ViewingDone from "../pages/Leads/lead/status/ViewingDone";
import Deal from "../pages/Leads/lead/status/deal/Deal";
import { DealsPdf } from "../pages/Accounts/deals/Deals";
import Contacts from "../pages/Contacts";
import AddProperty from "../pages/Properties/AddProperty";
import { AddTransaction } from "../pages/Accounts/deals/AddTransaction";
import { TransactionDetails } from "../pages/Accounts/deals/TransactionDetails";
import ForgetPassword from "../pages/ResetPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import LeadPool from "../pages/Leads/LeadPool";
import EditLeadPool from "../pages/Leads/EditLeadPool";


const SuspenseLayout = ({ children }) => {
  const DataProvider = ({ children }) => {
    const currentAgency = useSelector(selectCurrentagency);
    const { data: properties = [], isLoading } = useGetAgencyPropertiesQuery(
      currentAgency.agencyid,
    );

    const { data: agencyLeads = [], isLoading: LeadsLoading, refetch  } = useGetLeadsQuery(
      currentAgency.agencyid
    );


    return (
      <PropertiesData.Provider value={{ properties, isLoading }}>
        <LeadsData.Provider value={{ agencyLeads, LeadsLoading, refetch }}>
          {children}
        </LeadsData.Provider>
      </PropertiesData.Provider>
    );
  };
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <DataProvider>
        {children}
      </DataProvider>
    </Suspense>
  );
};

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  { path: "/Agency/TokenVerification", element: <ResetPassword /> },
  {
    path: "/",
    element: (
      <RequireAuth>
        <FullLayout />
      </RequireAuth>
    ),
    errorElement: <div>Page Not Found</div>,
    children: [
      { path: "/", element: <Dashboard /> },
      {
        path: PROPERTIES_ROUTE,
        element: (
          <SuspenseLayout>
            <Properties />
          </SuspenseLayout>
        )
      },
      {
        path: ADD_PROPERTY_ROUTE,
        element: (
          <SuspenseLayout>
            <AddProperty />
          </SuspenseLayout>)
      },
      {
        path: AGENTS_ROUTE,
        element: <Agents />,
      },
      {
        path: ADD_AGENT_ROUTE,
        element: <AddAgent />,
      },
      {
        path: PROFILE_ROUTE,
        element: <Profile />,
      },
      {
        path: EDIT_PROPERTY_ROUTE + ":propertyId",
        element: <EditProperty />,
      },

      {
        path: EDIT_AGENT_ROUTE + ":agentId",
        element: <EditAgent />,
      },

      {
        path: LEADS_ROUTE,
        element: (
          <SuspenseLayout>
            <Leads />
          </SuspenseLayout>
        )

      },
      {
        path: ADD_LEAD_ROUTE,
        element: <AddLead />,
      },
      {
        path: EDIT_LEAD_ROUTE + ":leadId",
        element: <EditLead />,
      },
      {
        path: LEAD_AGENT_ROUTE,
        element: (
          <SuspenseLayout>
            <Lead />
          </SuspenseLayout>
        )
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewLead />
          </SuspenseLayout>)
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE + APPOINTMENT_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewingArranged />
          </SuspenseLayout>)
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE + VIEWING_DONE_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewingDone />
          </SuspenseLayout>)
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE + DEAL_DEAL_ROUTE,
        element: (
          <SuspenseLayout>
            <Deal />
          </SuspenseLayout>)
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE + VIEW_DETAILS_ROUTE,
        element: (
          <SuspenseLayout>
            <LeadDetails />
          </SuspenseLayout>)
      },
      {
        path: LEAD_AGENT_ROUTE + VIEW_ROUTE + HISTORY_ROUTE,
        element: (
          <SuspenseLayout>
            <History />
          </SuspenseLayout>)
      },
      {
        path: CONTACTS_ROUTE,
        element: (
          <SuspenseLayout>
            <Contacts />
          </SuspenseLayout>)
      },
      {
        path: ACCOUNT_DEALS_ROUTE,
        element: (
          <SuspenseLayout>
            <DealsPdf />
          </SuspenseLayout>)
      },
      {
        path: TRANSACTION_DETAILS_ROUTE + "/:dealId",
        element: (
          <SuspenseLayout>
            <TransactionDetails />
          </SuspenseLayout>)
      },
      {
        path: ACCOUNT_ADD_TRANSACTION_ROUTE + ":dealId"  + "/:transactionId",
        element: (
          <SuspenseLayout>
            <AddTransaction />
          </SuspenseLayout>)
      },
      {
        path: LEADS_POOL_ROUTE,
        element: (
          <SuspenseLayout>
            <LeadPool />
          </SuspenseLayout>)
      },
    ],
  },
]);
