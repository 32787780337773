import { matchIsValidTel } from "mui-tel-input";

export const leadDefaultValues = {

  username: "",
  useremail: "",
  userphone: "",
  description: "",
  propertyid: ""

};

export const leadRules = {
  username: {
    required: "Name is required",
  },
  useremail: {
    validate: (value) => {
      if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return "Invalid email address";
      }
    }
  },
  leadtype: {
    required: "Lead Type is required",
  },
  mobileno: {
    // validate: {
    //   required: (value) => value ? true : "Mobile number is required",
    //   phone: (value) => {
    //     const countrycodes = [+92, +971];
  
    //     if (countrycodes.some((code) => value.startsWith(code.toString()))) {
    //       return true;
    //     }
  
    //     return matchIsValidTel(value) || "Invalid phone number";
    //   },
    // },
    required: "Phone Number is required",
  },
  description: {},
  propertyid: {},
  agentid: {
    required: "Agent is required",
  },

};
