// import { LEAD_TYPES, STATUS_TYPES } from "../../constants/leads";
import { Chip, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { AGENT_IMAGES_URL, PROPERTY_IMAGES_URL } from "../../constants/url";
import React, { useState } from "react";
import { LEAD_DEAL_DONE_ID, LEAD_TYPES, LEAD_VIEWING_ARRANGE_ID, LEAD_VIEWING_DONE_ID, STATUS_TYPES } from "../../constants/leads";
// import { Chip, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
// import { PROPERTY_IMAGES_URL } from "../../constants/url";
// import React, { useState } from "react";
import { correctDate } from "../../constants/customFunction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EDIT_LEAD_ROUTE, LEAD_AGENT_ROUTE, LEADS_POOL_EDIT_ROUTE } from "../../constants/routes";
import StatusDescription from "./lead/status/StatusDescription";
import { useLeadStatusQuery } from "../../redux/api/leadsApi";

export const PROPERTY_LEADS_COLUMNS = [
  {
    accessorKey: "firstname",
    header: "First Name",
    size: 30,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}
      >
        {row.original.firstname}
      </Link>
    ),
  },
  {
    accessorKey: "lastname",
    header: "Last Name",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}
      >
        {row.original.lastname}
      </Link>
    ),
  },
  {
    accessorKey: "useremail",
    header: "Email",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}
      >
        {row.original.useremail}
      </Link>
    ),
  },
  {
    accessorKey: "userphone",
    header: "Phone",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}
      >
        {row.original.userphone}
      </Link>
    ),
  },
  {
    accessorKey: "status",
    header: "Lead Status",
    size: 20,
    Cell: ({ row }) => {
      const properties = row.original.Properties;
      let displayText = '';
  
      if (properties && properties.length === 1) {
        const status = properties[0].leadStatus;
        displayText = STATUS_TYPES.find(option => option.id === status)?.name || 'Unknown Status';

      } else {
        displayText = 'Multiple'; // or any other text you prefer
      }
  
      return (
        <Link className="table-row-link" to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}>
          {displayText}
        </Link>
      );
    },
  },
  {
    accessorKey: "leads",
    header: "User  ",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
        to={`/agent/leads/${row.original.agentid}/view/${row.original.userid}`}
      >
        <span className="absolute -start-full transition-all group-hover:start-4">
          <svg
            className="size-3 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </span>

        <span className="text-sm font-medium transition-all group-hover:ms-4"> Leads </span>
      </Link>

    ),
  },
];
export const LEADS_POOL_COLUMNS = [
  {
    accessorKey: "firstname",
    header: "First Name",
    size: 30,
     },
  {
    accessorKey: "lastname",
    header: "Last Name",
    size: 20,
  },
  {
    accessorKey: "useremail",
    header: "Email",
    size: 20,
  },
  {
    accessorKey: "userphone",
    header: "Phone",
    size: 20,
  },
  {
    accessorKey: "userid",
    header: "User Id",
    size: 20,
  },
  {
    accessorKey: "leadid",
    header: "Lead Id",
    size: 20,
    Cell: ({ row }) => (
        <span>{row.original?.Properties[0]?.leadid}</span> 
    ),
},

];


export const VIEW_COLUMNS = [
  {
    accessorKey: "propertyid",
    header: "Property Image",
    size: 20,
    Cell: ({ row }) => (
      <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", gap: '5px' }}>
        <img
          src={`${PROPERTY_IMAGES_URL}${row.original.image}`}
          alt="property"
          style={{ maxHeight: "40px" }}
        />
        {/* <Chip label={row.original.propertyrefnum} size="small" sx={{ cursor: 'pointer', fontSize: 12 }} color="secondary" /> */}
      </div>
    ),
  },
  {
    accessorKey: "Lead ID",
    header: "Lead ID",
    size: 20,
    Cell: ({ row }) => {
      const propertyrefnum = row.original.propertyrefnum || '';
      const leadName = propertyrefnum ? propertyrefnum.split("-")[0] : '';
      const leadId = row.original.leadid;
      
      return (
        <div>
          <Chip 
            label={`${leadName ? `${leadName}-L-${leadId}` : `L-${leadId}`}`} 
            size="small" 
            sx={{ cursor: 'pointer', fontSize: 12, backgroundColor: "#56C662", color: "white" }} 
          />
        </div>
      );
    },
    
  },

  {
    accessorKey: "propertytitle",
    header: "Title",
    size: 10,
    Cell: ({row}) =>  (
      <div style={{ whiteSpace: "nowrap", width: "250px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {row.original?.propertytitle}
      </div>
    )
  },
  {
    accessorKey: "leadTypeName",
    header: "Lead Type",
    size: 20,
  },
  {
    accessorKey: "sourceName",
    header: "Source Type",
    size: 20,
  },
  {
    accessorKey: "mediumName",
    header: "Medium Name",
    size: 20,
  },
  {
    accessorKey: "priorityName",
    header: "Priority Name",
    size: 20,
  },
  {
    accessorKey: "leadTime",
    header: "Date",
    size: 20,
    Cell: ({ row }) => (
      <div>
        {new Date(row.original.leadTime).toLocaleDateString()}
      </div>
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 25,
    Cell: ({ row }) => {
      const [status, setStatus] = React.useState(row.original.status);
      const [showComponent, setShowComponent] = useState(false);
      const navigate = useNavigate();
      const { data: STATUS = [] } = useLeadStatusQuery();

      const handleChange = (event) => {
        const newStatus = event.target.value;
        setStatus(newStatus);
  
        const currentPath = window.location.pathname;
        let newPath;
        
        if (newStatus === LEAD_VIEWING_ARRANGE_ID) {
          newPath = `${currentPath}/viewingarranged/${row.original.leadid}`;
        } else if (newStatus === LEAD_VIEWING_DONE_ID) {
          newPath = `${currentPath}/viewdone/${row.original.leadid}`;
        } else if (newStatus === LEAD_DEAL_DONE_ID) {
          newPath = `${currentPath}/deal/${row.original.leadid}`;
        }
  
        if (newStatus === LEAD_VIEWING_ARRANGE_ID || newStatus === LEAD_VIEWING_DONE_ID || newStatus === LEAD_DEAL_DONE_ID) {
          setShowComponent(false); 
          navigate(newPath); 
        } else {
          setShowComponent(true); 
        }
      };
      return (
        <>
          <TextField
            variant="outlined"
            size="small"
            select
            value={status}
            // onClick={statusHandler}
            onChange={handleChange}
            SelectProps={{
              displayEmpty: true,
              MenuProps: {
                sx: { maxHeight: "380px" },
              },
              renderValue: (selected) => {
                const selectedStatus = STATUS.find(option => option.statusId === row.original.leadStatus);
                return selectedStatus ? selectedStatus.statusName : <span>Status</span>;
              },
            }}
            sx={{ width: '300px' }}
          >
            {STATUS.map((option) => (
              <MenuItem key={option.statusId} value={option.statusId}>
                {option.statusName}
              </MenuItem>
            ))}
          </TextField>
          {
            showComponent && (
              <div className="fixed top-0 left-0 xl:top-5 xl:left-20 flex items-center justify-center w-full h-[100vh] z-10 bg-customGray">
                <div className="w-auto sm:w-[600px] h-[350px] bg-white rounded-xl flex flex-col justify-center items-center">
                  <StatusDescription setShowVerify={setShowComponent} selectedLeadStatus={status} leadData={row.original} />
                </div>
              </div>
            )
          }
        </>
      );
    },
  },

  

  {
    accessorKey: "details",
    header: "Property",
    size: 15,
    Cell: ({ row }) => {
      const { id } = useParams();
  
      return row.original.propertyid === null ? (
        <span>Disable</span>
      ) : (
        <Link
          className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
          to={`details/${row.id}`}
        >
          <span className="absolute -start-full transition-all group-hover:start-4">
            <svg
              className="size-3 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>
          <span className="text-sm font-medium transition-all group-hover:ms-4">Details</span>
        </Link>
      );
    },
  },  
  {
    accessorKey: "edit",
    header: "Edit",
    size: 15,
    Cell: ({ row }) => {

      return (
        <Link
          className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
          to={EDIT_LEAD_ROUTE + row.original.leadid}
        >
          <span className="absolute -start-full transition-all group-hover:start-4">
            <svg
              className="size-3 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>

          <span className="text-sm font-medium transition-all group-hover:ms-4"> Edit </span>
        </Link>

      )
    },
  },
  {
    accessorKey: "leadHistory",
    header: "History",
    size: 20,
    Cell: ({ row }) => {
      const currentPath = window.location.pathname;
      const segments = currentPath.split("/");
      const ID = segments[3];
      return (
        <Link
          className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
          to={`history/${row.original.leadid}`}
        >
          <span className="absolute -start-full transition-all group-hover:start-4">
            <svg
              className="size-3 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>

          <span className="text-sm font-medium transition-all group-hover:ms-4"> History </span>
        </Link>

      )
    }
  },
];


export const LEAD_HISTORY_COLUMNS = [
  {
    accessorKey: "leadStatus",
    header: "Status",
    size: 20,
    Cell: ({ row }) => (
      <div>{STATUS_TYPES.find((item) => item.id === row.original.leadStatus)?.name}</div>
    )
  },
  {
    accessorKey: "leadDate",
    header: "Date",
    size: 20,
    Cell: ({ row }) => {
      const date = correctDate(row.original?.leadDate)
      return (
        < div >
          {date}
        </div >
      )
    }
  },
  {
    accessorKey: "leadDate",
    header: "Time",
    size: 20,
    Cell: ({ row }) => {
      const leadDate = row.original.leadDate;
      if (leadDate) {
        const timeString = new Date(leadDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        return timeString;
      } else {
        return "";
      }
    }
  },

  { accessorKey: "note", header: "Description", size: 20 },
];


export const AGENT_COLUMNS = [
  {
    accessorKey: "AgentId",
    header: "ID",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.AgentId}
      </Link>
    ),
  },
  {
    header: "Image",
    accessorKey: "AgentImage",
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        <img
          src={AGENT_IMAGES_URL + row.original.AgentImage}
          alt={"agent"}
          style={{ width: "70px", height: "70px" }}
        />
      </Link>
    ),
    size: 30,
  },
  {
    accessorKey: "AgentRegNo",
    header: "Reg No",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.AgentRegNo}
      </Link>
    ),
  },
  {
    accessorKey: "AgentName",
    header: "Name",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.AgentName}
      </Link>
    ),
  },
  {
    accessorKey: "AgentEmail",
    header: "Email",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.AgentEmail}
      </Link>
    ),
  },
  {
    accessorKey: "AgentPhone",
    header: "Phone",
    size: 20,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.AgentPhone}
      </Link>
    ),
  },
  {
    header: "Description",
    accessorKey: "AgentDescription",
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        <Tooltip title={row.original.AgentDescription} placement="top">
          <div
            style={{
              width: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row.original.AgentDescription}
          </div>
        </Tooltip>
      </Link>
    ),
    size: 30,
  },
  {
    accessorKey: "LeadCountPerAgent",
    header: "Total Leads",
    size: 10,
    Cell: ({ row }) => (
      <Link
        className="table-row-link"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        {row.original.LeadCountPerAgent}
      </Link>
    ),
  },
  {
    accessorKey: "view",
    header: "Lead    ",
    size: 30,
    Cell: ({ row }) => (
      <Link
        className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
        to={`/agent/leads/${row.original.AgentId}`}
      >
        <span className="absolute -start-full transition-all group-hover:start-4">
          <svg
            className="size-3 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </span>

        <span className="text-sm font-medium transition-all group-hover:ms-4">Details</span>
      </Link>
    ),
  },
  
];
