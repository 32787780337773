import React from "react";
import { LEAD_HISTORY_COLUMNS } from "../leadsData";
import { useGetLeadsHistoryQuery } from "../../../redux/api/leadsApi";
import { MaterialReactTable } from "material-react-table";
import { useLeadsData } from "../../../hooks/useContext/leadsData";
import {
    Box,
    Typography,
    Grid,
    Paper,
    useMediaQuery,
  } from "@mui/material";

const LeadTable = ({ leads, isLoading, columns }) => {

    const isMobileScreen = useMediaQuery("(max-width:768px)");

    return (
        <MaterialReactTable
            enableRowActions={false}
            columns={columns}
            data={leads}
            getRowId={(row) => row.leadid}
            state={{
                showSkeletons: isLoading,
            }}
            initialState={{ density: "compact" }}
            enableDensityToggle={false}
            enableStickyHeader={!isMobileScreen}
            muiTableContainerProps={{
                sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 320px)" },
            }}
        />
    );
}

export default function History() {
    const currentPath = window.location.pathname;
    const segments = currentPath.split("/");
    const agentId = segments[3];
    const userID = segments[5];
    const leadId = segments[segments.length - 1];
    const token = localStorage.getItem('token');

    const leadsHistory = useGetLeadsHistoryQuery(
        {
            userid: agentId || null,
            token: token || null,
            leadId: leadId || null
        }
    );
    const { agencyLeads, LeadsLoading: isLoading } = useLeadsData();


    // Filter leads based on agent id 
    const filterAgentId = agencyLeads && agencyLeads?.filter((item) => item.agentid === parseInt(agentId));

    // Filter leads based on user id
    const filterUserId = filterAgentId && filterAgentId?.filter((item) => item.userid === parseInt(userID));

    // Ensure filterUserId has elements before accessing index 0
    const filterLeadId = filterUserId && filterUserId.length > 0
        ? filterUserId[0].Properties?.filter((item) => item.leadid === parseInt(leadId))
        : [];

    return (
        <Box p={3}>
        {!isLoading && (
          <>
            <Typography variant="h3" gutterBottom>
              Lead Information
            </Typography>
            <Paper elevation={1} sx={{ p: 3, mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    {filterLeadId[0]?.propertytitle}
                  </Typography>
                  <LeadInfo
                    label="Lead Name"
                    value={`${filterUserId[0]?.firstname} ${filterUserId[0]?.lastname}`}
                  />
                  <LeadInfo
                    label="Lead Phone"
                    value={filterUserId[0]?.userphone}
                  />
                  <LeadInfo
                    label="Ref No"
                    value={filterLeadId[0]?.propertyrefnum}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LeadInfo
                    label="Agent Name"
                    value={filterUserId[0]?.agentname}
                  />
                  <LeadInfo
                    label="Agent Email"
                    value={filterUserId[0]?.agentemail}
                  />
                  <LeadInfo
                    label="Agency Name"
                    value={filterLeadId[0]?.agencyname}
                  />
                  <LeadInfo
                    label="Agency Email"
                    value={filterLeadId[0]?.agencyemail}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          History Details Table
        </Typography>
        <LeadTable
          key={2}
          columns={LEAD_HISTORY_COLUMNS}
          leads={leadsHistory.data || []}
          isLoading={leadsHistory.isLoading}
        />
      </Box>
    );
  }
  
  const LeadInfo = ({ label, value }) => (
    <Box mb={1}>
      <Typography variant="body2" color="textSecondary">
        <strong>{label}:</strong> {value}
      </Typography>
    </Box>
  );
  