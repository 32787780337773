import React from "react";
import { Button, styled, useMediaQuery } from "@mui/material";
import { PROPERTY_LEADS_COLUMNS } from "../leadsData";
import { MaterialReactTable } from "material-react-table";
import { useSelector } from "react-redux";
// import { selectCurrentAgent } from "../../redux/features/authSlice";
import { useLeadsData } from "../../../hooks/useContext/leadsData";


const LeadTable = ({ leads, isLoading, columns }) => {

  const isMobileScreen = useMediaQuery("(max-width:768px)");

  return (
    <MaterialReactTable
      enableRowActions={false}
      columns={columns}
      data={leads}
      getRowId={(row) => row.leadid}
      state={{
        showSkeletons: isLoading,
      }}
      initialState={{ density: "compact" }}
      enableDensityToggle={false}
      enableStickyHeader={!isMobileScreen}
      muiTableContainerProps={{
        sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 320px)" },
      }}
    />
  );
}

export default function Lead() {

  const currentPath = window.location.pathname;
  const segments = currentPath.split("/");
  const ID = segments[3];
  const { agencyLeads, LeadsLoading } = useLeadsData();
  const filterAgentId = agencyLeads && agencyLeads.filter((item) => item.agentid === parseInt(ID));

  return (
    <div>
      <StyledTop>
        <StyledButton
          size='small'
          sx={{
            color: "white",
            "&:active": {
              bgcolor: "black",
            },
          }}
          variant={"contained"}
        >
          All Leads
        </StyledButton>

       
      </StyledTop>

      <LeadTable
        key={2}
        columns={PROPERTY_LEADS_COLUMNS}
        leads={filterAgentId || []}
        isLoading={LeadsLoading}
      />
    </div>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));

const StyledTop = styled("div")(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  flexWrap: 'wrap',
  rowGap: '20px'
}));